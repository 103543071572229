import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../models/response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_FILE}Files`)
  }

  layThongTinFile(listFile): Promise<ResponseData> {
    const url = `${this.table}/GetsByIds`;
    return this.httpClient.post<ResponseData>(url, listFile).toPromise();
  }

  getFile(id, size = 'm'): Promise<ResponseData> {
    
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }
  getDocument(id): Promise<ResponseData> {
    const url = `${this.table}/DownloadFile/${id}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  getImage(id, size = 'm'): Observable<Blob> {
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getImageDownload(id, size = 'm'): Observable<Blob> {
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.httpClient.get(url, {headers: headers, responseType: 'blob' });
  }

  uploadImage(file):Observable<ResponseData>{
    let formData = new FormData();
    formData.append('file',file);
    const url = `${this.table}/UploadFile`;
    return this.httpClient.post<ResponseData>(url,formData);
  }

  //#region Thông tin folder
  GetListFolderByUnitId() {
    const url = `${environment.SERVICE_API_FILE}api/Folder/GetListFolderByUnitId`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  CreateFolderByUnitId(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/CreateFolderByUnitId`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetFolderDetailById(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/GetFolderDetailById`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  UpdateFolderById(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/UpdateFolderById`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetListFileByFolderId(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/GetListFileByFolderId`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  UploadFileInFolder(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/UploadFileInFolder`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RemoveFolderById(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RemoveFolderById`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RemoveFileById(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RemoveFileById`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RecoverFolderInTrash(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RecoverFolderInTrash`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RecoverFileInTrash(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RecoverFileInTrash`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RemoveFolderInTrash(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RemoveFolderInTrash`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  RemoveFileInTrash(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/RemoveFileInTrash`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetFolderFileInTrash(body) {
    const url = `${environment.SERVICE_API_FILE}api/Folder/GetFolderFileInTrash`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  //#endregion
}
