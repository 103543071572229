import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MESSAGE } from 'src/app/constants/message';
import { UserLogin } from 'src/app/models/userlogin';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-extension-layout',
  templateUrl: './extension-layout.component.html',
  styleUrls:['./extension-layout.component.scss']
})

export class ExtensionLayoutComponent implements OnInit {


  showLayout: boolean = true;
  userLogin:any=null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _userService: UserService,
  ) {
    this.getUserLogin();
  }

  ngOnInit(): void {
    this.route.firstChild?.data.subscribe((data) => {
      this.showLayout = !data['noLayout'];
    });
  }
  getUserLogin() {
    this._userService.getCurrentUser().subscribe(
      (user: UserLogin) => {
        if (user) {
          this.userLogin = user;
          console.log(this.userLogin);
        }
      }
    );
  }
  hasRole(listRole):boolean{
    let userRoleCodes= this.userLogin.roleCodes;
    return userRoleCodes.some(role=>listRole.includes(role));
  }
}
