import { FileService } from './../../services/file.service';
import { Component, ElementRef, forwardRef, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { DropdownCommonCoreService } from 'src/app/services/caidat-hethong/common.service';
import { UserLogin } from 'src/app/models/userlogin';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { FileAddFolderComponent } from '../file-add-folder/file-add-folder.component';
import { UserService } from 'src/app/services/core/user.service';
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileManagementComponent),
      multi: true
    }
  ]
})
export class FileManagementComponent implements OnInit, ControlValueAccessor {
  @Input() extention: string = ''
  @Input() isMultiple: boolean = false
  urlApi: string = "";
  fileIds: any;
  @Input() fileType: string;
  @Input() isManagement: boolean = false;
  fileList: NzUploadFile[] = [
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    // },
  ];
  isImageAllowed = false;
  listFileUpload = [];
  previewVisible = false;

  widthModal: number = 900;
  userLogin: UserLogin = null;

  isUploading = false;
  constructor(
    private _modalService: NzModalService,
    private _notification: NzNotificationService,
    private _fileService: FileService,
    private spinner: NgxSpinnerService,
    private _userService: UserService,
    private nzContextMenuService: NzContextMenuService,
    private http: HttpClient,
  ) {
    this.urlApi = `${environment.SERVICE_API_FILE}Files/UploadFile`;
  }

  //#region handle formControler
  public onChange: (data: any) => void;
  public onTouched: () => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  
  writeValue(obj: any): void {
    console.log(obj);
    if (this.isMultiple) {
      if (obj) {
        obj = obj.toLowerCase();
        this.fileIds = (obj as string).split(';')
      } else {
        this.fileIds = []
      }
    } else {
      if (obj) {
        obj = obj.toLowerCase();
        this.fileIds = [obj]
      } else {
        this.fileIds = [];
      }
    }
  }
  //#endregion

  ngOnInit(): void {
    this._userService.getCurrentUser().subscribe(
      (user: UserLogin) => {
        if (user) {
          this.userLogin = user;
        } else {
          //this._notification.error(MESSAGE.ERROR, 'Lấy thông tin thất bại');
        }
      },
      (err) => {
        this._notification.error(MESSAGE.ERROR, err);
      }
    );
  }

  activatedFolder = null;
  activateFile = null;

  isFolderTrash = false;
  activeFolderTrash = null;

  @ViewChild('templateListFolders', { static: true }) templateListFolders!: TemplateRef<any>;
  modalRef!: NzModalRef;
  folderTree: any[] = [];
  onClickShowFiles() {
    this.spinner.show();
    this._fileService.GetListFolderByUnitId().then((res) => {
      if (res.success) {
        let lstFolder = res.data;
        this.folderTree = this.buildTree(lstFolder);
        this.fullPath = this.getNodePath(this.folderTree[0], this.folderTree);
        this.activatedFolder = this.folderTree[0];
        this.activateFile = this.folderTree[0];
        this.loadListFile(this.folderTree[0]);
      }
      this.modalRef = this._modalService.create({
        nzTitle: 'Danh sách files',
        nzContent: this.templateListFolders,
        nzFooter: null,
        nzWidth: '70%'
      });
      this.spinner.hide();
    }).catch((err) => {
      this.spinner.hide();
    });
  }

  loadFolderTree() {
    this.spinner.show();
    this._fileService.GetListFolderByUnitId().then((res) => {
      if (res.success) {
        let lstFolder = res.data;
        this.folderTree = this.buildTree(lstFolder);
      }
      this.spinner.hide();
    }).catch((err) => {
      this.spinner.hide();
    });
  }

  buildTree(list: any[]): any[] {
    const map = new Map<string, any>();
    list.forEach(item => map.set(item.id, { ...item, children: [] }));
    const tree: any[] = [];
    list.forEach(item => {
      if (item.idParent && map.has(item.idParent)) {
        map.get(item.idParent).children.push(map.get(item.id));
      } else {
        const rootNode = map.get(item.id);
        rootNode.expanded = true;
        tree.push(rootNode);
      }
    });
    return tree;
  }

  openFolder(data: NzTreeNode | NzFormatEmitEvent): void {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  fullPath = [];
  lstInFolder = [];
  activeNode(data: NzFormatEmitEvent): void {
    if (!this.activatedFolder || this.activatedFolder.id != data.node.origin.id) {
      //Clear selected
      this.clearSelected(this.folderTree);
      this.folderTree = [...this.folderTree];
      data.node.origin.selected = true;
      this.activatedFolder = data.node.origin;

      //Load file và folder con của folder này
      this.fullPath = this.getNodePath(data.node.origin, this.folderTree);
      this.loadListFile(data.node.origin);
    }
  }

  loadListFile(folder) {
    const body = {
      folderId: folder.id,
      extention: this.extention
    }
    if (folder.typeSystem == 'TRASH') {
      this.isFolderTrash = true;
      this.spinner.show();
      this._fileService.GetFolderFileInTrash(body).then((res) => {
        if (res.success) {
          this.lstInFolder = res.data;
        }
        this.spinner.hide();
      }).catch((err) => {
        this.spinner.hide();
      });
    } else {
      this.isFolderTrash = false;
      this.spinner.show();
      this._fileService.GetListFileByFolderId(body).then((res) => {
        if (res.success) {
          this.lstInFolder = res.data;
        }
        this.spinner.hide();
      }).catch((err) => {
        this.spinner.hide();
      });
    }
  }

  clearSelected(nodes: any[]): void {
    nodes.forEach(node => {
      node.selected = false;
      if (node.children) {
        this.clearSelected(node.children);
      }
    });
  }

  getNodePath(node, tree, path = []) {
    if (!node) return path;
    path.unshift(node);
    let parent = this.findNodeById(node.idParent, tree);
    if (parent) {
      return this.getNodePath(parent, tree, path);
    }
    return path;
  }

  findNodeById(id, tree) {
    for (let node of tree) {
      if (node.id === id) return node;
      if (node.children) {
        let found = this.findNodeById(id, node.children);
        if (found) return found;
      }
    }
    return null;
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent, node): void {
    const folder = node.origin;
    //Folder trash thì không hiển thị gì
    if (folder.typeSystem != 'TRASH') {
      this.nzContextMenuService.create($event, menu);
      this.activateFile = folder;
    }
  }

  contextMenuDoNothing(event: MouseEvent) {
    event.preventDefault();
  }

  onClickNewFolder(event: MouseEvent) {
    event.preventDefault();
    const data = this.activatedFolder;
    let modal = this._modalService.create({
      nzTitle: 'Thêm mới thư mục 1',
      nzContent: FileAddFolderComponent,
      nzClosable: true,
      nzFooter: null,
      nzWidth: 800,
      nzComponentParams: {
        idParent: data.id
      },
    });
    modal.afterClose.subscribe((res) => {
      this.spinner.show();
      this.loadListFile(data);
      this.loadFolderTree();
    });
  }

  onClickNewFolderInFolder(event: MouseEvent) {
    event.preventDefault();
    const data = this.activateFile;
    let modal = this._modalService.create({
      nzTitle: 'Thêm mới thư mục 2',
      nzContent: FileAddFolderComponent,
      nzClosable: true,
      nzFooter: null,
      nzWidth: 800,
      nzComponentParams: {
        idParent: data.id
      },
    });
    modal.afterClose.subscribe((res) => {
      this.spinner.show();
      this.loadFolderTree();
    });
  }

  onClickUpdateFolderInFolder(event: MouseEvent) {
    event.preventDefault();
    const data = this.activateFile;
    let modal = this._modalService.create({
      nzTitle: 'Cập nhật thư mục',
      nzContent: FileAddFolderComponent,
      nzClosable: true,
      nzFooter: null,
      nzWidth: 800,
      nzComponentParams: {
        idParent: data.id,
        isUpdate: true
      },
    });
    modal.afterClose.subscribe((res) => {
      this.spinner.show();
      this.loadFolderTree();
    });
  }

  onClickRemoveFolder(event: MouseEvent) {
    event.preventDefault();
    const data = this.activateFile;
    this._modalService.confirm({
      nzClosable: false,
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có chắc chắn muốn xóa thư mục không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Không',
      nzOkDanger: true,
      nzWidth: 500,
      nzOnOk: () => {
        const body = {
          id: data.id
        }
        this._fileService.RemoveFolderById(body).then((res) => {
          if (res) {
            this.loadFolderTree();
            this.loadListFile(this.activatedFolder);
            this._notification.success(MESSAGE.SUCCESS, "Xóa thư mục thành công");
          }
        }).catch((err) => {
          this._notification.error(MESSAGE.ERROR, "Xóa thư mục thất bại");
          this.spinner.hide();
        });
      },
    });
  }

  onClickRemoveFile(event: MouseEvent) {
    event.preventDefault();
    const data = this.activateFile;
    this._modalService.confirm({
      nzClosable: false,
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có chắc chắn muốn xóa file không?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Không',
      nzOkDanger: true,
      nzWidth: 500,
      nzOnOk: () => {
        const body = {
          id: data.id
        }
        this._fileService.RemoveFileById(body).then((res) => {
          if (res) {
            this.spinner.show();
            this.loadListFile(this.activatedFolder);
            this._notification.success(MESSAGE.SUCCESS, "Xóa file thành công");
          }
        }).catch((err) => {
          this._notification.error(MESSAGE.ERROR, "Xóa file thất bại");
          this.spinner.hide();
        });
      },
    });
  }

  onPathClick(path) {
    this.activatedFolder = path;
    this.fullPath = this.getNodePath(path, this.folderTree);
    this.loadListFile(path);
  }

  onClickChooseFolder(file) {
    this.activatedFolder = file;
    this.fullPath = this.getNodePath(file, this.folderTree);
    this.loadListFile(file);
  }

  onClickActiveFile(file): void {
    if (this.isMultiple) {
      const existingFile = this.lstInFolder.find(f => f.id === file.id);
      if (existingFile) {
        existingFile.isSelected = !existingFile.isSelected;
      } 
    } else {
      this.lstInFolder.forEach(f => f.isSelected = false);
      file.isSelected = true;
    }
  }
  

  onClickChooseFile(file) {

  }

  onClickOpenFile(event: MouseEvent) {
    const file = this.activateFile;
    this._fileService.getFile(file.id).then(res => {
      if (res.success) {
        const listFile = res.data;
        if (listFile && listFile.length > 0) {
          this.showImageModal(listFile[0].url);
        }
      } else {
        this._notification.error('Lỗi', res.message);
      }
    });
  }

  showImageModal(imageUrl: string): void {
    this._modalService.create({
      nzTitle: 'Xem ảnh',
      nzContent: `<img src="${imageUrl}" alt="file image" style="width: 100%; max-height: 500px; object-fit: contain;">`,
      nzFooter: null,
      nzWidth: '600px'
    });
  }

  @ViewChild('fileInput') fileInput!: ElementRef;
  onClickNewFile(event: MouseEvent) {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  @ViewChild('fileInputFolder') fileInputFolder!: ElementRef;
  onClickNewFileInFolder(event: MouseEvent) {
    event.preventDefault();
    this.fileInputFolder.nativeElement.click();
  }

  contextMenuAction($event: MouseEvent, menuAction: NzDropdownMenuComponent): void {
    $event.stopPropagation();
    this.nzContextMenuService.create($event, menuAction);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      // Xử lý upload file ở đây
      const formData = new FormData();
      for (let i = 0; i < input.files.length; i++) {
        formData.append('files', input.files[i]);
      }
      formData.append('idFolderUnit', this.activatedFolder.id);
      this.spinner.show();
      this._fileService.UploadFileInFolder(formData).then((res) => {
        if (res.success) {
          this._notification.success('Thông báo', 'Tải file thành công');
          this.loadListFile(this.activatedFolder);
        }
        this.spinner.hide();
      }).catch((err) => {
        this._notification.error('Thông báo', 'Tải file thất bại');
        this.spinner.hide();
      });
    }
    input.value = '';
  }

  onFileSelectedInFolder(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile = input.files[0];
      // Xử lý upload file ở đây
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('idFolderUnit', this.activateFile.id);
      this._fileService.UploadFileInFolder(formData).then((res) => {
        if (res.success) {
          this._notification.success('Thông báo', 'Tải file thành công');
        }
        this.spinner.hide();
      }).catch((err) => {
        this._notification.error('Thông báo', 'Tải file thất bại');
        this.spinner.hide();
      });
    }
  }

  contextMenuFolder($event: MouseEvent, menuFolder: NzDropdownMenuComponent, file): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (file.typeSystem != 'TRASH') {
      this.activateFile = file;
      this.nzContextMenuService.create($event, menuFolder);
    }
  }

  contextMenuFile($event: MouseEvent, menuFile: NzDropdownMenuComponent, file): void {
    $event.stopPropagation();
    this.activateFile = file;
    this.nzContextMenuService.create($event, menuFile);
  }

  contextMenuFolderTrash($event: MouseEvent, menuFolderTrash: NzDropdownMenuComponent, folderTrash): void {
    $event.stopPropagation();
    this.activeFolderTrash = folderTrash;
    this.nzContextMenuService.create($event, menuFolderTrash);
  }

  onClickRecoverFolder($event: MouseEvent) {
    const body = {
      id: this.activeFolderTrash.id
    }
    this._fileService.RecoverFolderInTrash(body).then((res) => {
      if (res) {
        this.spinner.show();
        this.loadFolderTree();
        this.loadListFile(this.activatedFolder);
        this._notification.success(MESSAGE.SUCCESS, "Khôi phục thư mục thành công");
      }
    }).catch((err) => {
      this._notification.error(MESSAGE.ERROR, "Khôi phục thư mục thất bại");
      this.spinner.hide();
    });
  }

  onClickRemoveFolderInTrash($event: MouseEvent) {
    const body = {
      id: this.activeFolderTrash.id
    }
    this._fileService.RemoveFolderInTrash(body).then((res) => {
      if (res) {
        this.spinner.show();
        this.loadListFile(this.activatedFolder);
        this._notification.success(MESSAGE.SUCCESS, "Xóa thư mục thành công");
      }
    }).catch((err) => {
      this._notification.error(MESSAGE.ERROR, "Xóa thư mục thất bại");
      this.spinner.hide();
    });
  }

  contextMenuFileTrash($event: MouseEvent, menuFolderTrash: NzDropdownMenuComponent, fileTrash): void {
    $event.stopPropagation();
    this.activeFolderTrash = fileTrash;
    this.nzContextMenuService.create($event, menuFolderTrash);
  }

  onClickRecoverFile($event: MouseEvent) {
    const body = {
      id: this.activeFolderTrash.id
    }
    this._fileService.RecoverFileInTrash(body).then((res) => {
      if (res) {
        this.spinner.show();
        this.loadListFile(this.activatedFolder);
        this._notification.success(MESSAGE.SUCCESS, "Khôi phục file thành công");
      }
    }).catch((err) => {
      this._notification.error(MESSAGE.ERROR, "Khôi phục file thất bại");
      this.spinner.hide();
    });
  }

  onClickRemoveFileInTrash($event: MouseEvent) {
    const body = {
      id: this.activeFolderTrash.id
    }
    this._fileService.RemoveFileInTrash(body).then((res) => {
      if (res) {
        this.spinner.show();
        this.loadListFile(this.activatedFolder);
        this._notification.success(MESSAGE.SUCCESS, "Xóa file thành công");
      }
    }).catch((err) => {
      this._notification.error(MESSAGE.ERROR, "Xóa file thất bại");
      this.spinner.hide();
    });
  }

  onClickCancel() {
    if (this.modalRef) {
      this.modalRef.destroy();
    }
  }

  onClickOk() {
    this.fileIds = this.lstInFolder
      .filter(f => f.isSelected)
      .map(x => x.id);
    this.onChange(this.isMultiple ? this.fileIds.join(';') : this.fileIds[0] || null);
    this.onClickCancel();
  }
}
