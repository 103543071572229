import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseDTO, ResponseData } from 'src/app/models/response';
import { BaseService } from '../base.service';
import { KhachHangInfoBasic } from 'src/app/components/switchboard/omicall-view-dial/omicall-view-dial.component';

export function getOptions(maDonVi) {
  let headers = new HttpHeaders().set('maDonVi', maDonVi);
  const options = {
    headers: headers,
  };
  return options;
}

@Injectable({
  providedIn: 'root',
})
export class KhachHangService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.SERVICE_API_CUSTOMER}api/KhachHang`);
  }

  TimTheoDieuKien(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/TimTheoDieuKien`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchKhachHang360(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/SearchKhachHang360`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchKhachHang360_GetIdsKhachHang(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/SearchKhachHang360_GetIdsKhachHang`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchKhachHang360_TotalRecord(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/SearchKhachHang360_TotalRecord`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchKhachHang360_ThongKe(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/SearchKhachHang360_ThongKe`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  findByCondition(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/FindByCondition`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchByKeyword(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/SearchByKeyword`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  CreateKH(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/ThemMoi_V2`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetKhachHangById(idKhachHang): Promise<ResponseData> {
    const url = `${this.table}/GetKhachHangById/${idKhachHang}`;
    return this.GetRequest(url);
  }

  GetHangKhachHangByIdKh(idKhachHang): Promise<ResponseData> {
    const url = `${this.table}/GetHangKhachHangByIdKh/${idKhachHang}`;
    return this.GetRequest(url);
  }

  GetSoLieuKhachHangTheoDonVi() {
    const url = `${this.table}/GetSoLieuKhachHangTheoDonVi`;
    return this.GetRequest(url);
  }

  DownloadFileImportKhachHang() {
    const url = `${this.table}/DownloadFileImportKhachHang`;
    return this.getDownload(url);
  }

  DownloadDataWrongKhachHang(id) {
    const url = `${this.table}/DownloadDataWrongKhachHang/${id}`;
    return this.getDownload(url);
  }

  TimKiemHangKhachHangTheoDieuKien(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/TimKiemHangKhachHangTheoDieuKien`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  InsertBenhLyKhachHang(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/InsertBenhLyKhachHang`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetListBenhLyTheoKhachHang(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/GetListBenhLyTheoKhachHang`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetDetailBenhLy(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/GetDetailBenhLy`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  UpdateBenhLy(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/UpdateBenhLy`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  CapNhatThongTinQueQuan(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/CapNhatThongTinQueQuan`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  DeleteBenhLy(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/DeleteBenhLy`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  //Cập nhật ghi chú khách hàng.
  CapNhatGhiChuKhachHang(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/CapNhatGhiChuKhachHang`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  createOrUpdateAccount(payload: any) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/CreateOrUpdatePrivateAccount`;
    return this._httpClient.post<ResponseData>(url, payload).toPromise();
  }

  createMultiPrivateAccount(payload: any) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/KhachHang/CreateMultiPrivateAccount`;
    return this._httpClient.post<ResponseData>(url, payload).toPromise();
  }

  getByIdKhachHangPrivate(idKhach: Guid, idDonVi: Guid) {
    const url =
      `${environment.SERVICE_API_CUSTOMER}api/KhachHang/GetByIdKhachHangPrivate/` +
      idKhach +
      `/${idDonVi}`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }

  GetInfoKhachHangBySoDienThoai(soDienThoai): Promise<ResponseDTO<KhachHangInfoBasic>> {
    const url = `${this.table}/GetInfoKhachHangBySoDienThoai/${soDienThoai}`;
    return this.GetRequest(url);
  }


  GetInfoKhachHangByOmiCall(soDienThoai): Promise<ResponseDTO<KhachHangInfoBasic>> {
    const url = `${this.table}/GetInfoKhachHangByOmiCall/${soDienThoai}`;
    return this.GetRequest(url);
  }

  TimTheoSdtEdit(sdt): Promise<ResponseData> {
    const url = `${this.table}/TimTheoSdtEdit/${sdt}`;
    return this.get<ResponseData>(url).toPromise();
  }

  // lịch sử tương tác
  TuongTac_TimTheoDieuKien(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/TuongTacKhachHang/TimTheoDieuKien`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  DanhSachKieuLoaiTuongTac() {
    const url = `${environment.SERVICE_API_CUSTOMER}api/TuongTacKhachHang/DanhSachKieuLoaiTuongTac`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }

  DanhSachKieuLoaiTacDong() {
    const url = `${environment.SERVICE_API_CUSTOMER}api/TuongTacKhachHang/DanhSachKieuLoaiTacDong`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }

  GetFieldChanges(body) {
    const url = `${environment.SERVICE_API_CUSTOMER}api/TuongTacKhachHang/GetFieldChanges`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  PhanBoKhachHangChoMKT(body) {
    const url = `${this.table}/PhanBoKhachHangChoMKT`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  PhanBoKhachHangChoSale(body) {
    const url = `${this.table}/PhanBoKhachHangChoSale`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  //
}
