<nz-divider nzOrientation="left" nzDashed [nzText]="'Đổi mật khẩu'"></nz-divider>
<form nz-form [formGroup]="formInput">
  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24"
      nzFor="oldPassword">Mật khẩu hiện tại</nz-form-label>
    <nz-form-control [nzLg]="fromChatPage ? 24 : 17" [nzSm]="17" [nzXs]="24" [nzErrorTip]="phoneErrorTpl">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input nz-input formControlName="oldPassword" [type]="passwordVisible ? 'text' : 'password'" />
      </nz-input-group>
      <ng-template #phoneErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
      </ng-template>
      <ng-template #pricePrefix>
        <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24"
      nzFor="newPassword">Mật khẩu mới</nz-form-label>
    <nz-form-control [nzLg]="fromChatPage ? 24 : 17" [nzSm]="17" [nzXs]="24" [nzErrorTip]="phoneErrorTpl1">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input nz-input formControlName="newPassword" [type]="passwordVisible ? 'text' : 'password'" />
      </nz-input-group>
      <ng-template #phoneErrorTpl1 let-control>
        <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
        <ng-container *ngIf="control.hasError('pattern')">
          Ít nhất 8 ký tự.<br />
          Ít nhất một ký tự chữ thường.<br />
          Ít nhất một ký tự chữ in hoa.<br />
          Ít nhất một chữ số.<br />
          Ít nhất một ký tự đặc biệt từ danh sách [#?!&#64;$%^&*-].<br />
        </ng-container>
      </ng-template>
      <ng-template #pricePrefix>
        <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24"
      nzFor="confirmPassword">Nhập lại mật khẩu mới</nz-form-label>
    <nz-form-control [nzLg]="fromChatPage ? 24 : 17" [nzSm]="17" [nzXs]="24" [nzErrorTip]="phoneErrorTpl2">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input nz-input formControlName="confirmPassword" [type]="passwordVisible ? 'text' : 'password'" />
      </nz-input-group>
      <ng-template #phoneErrorTpl2 let-control>
        <ng-container *ngIf="control.hasError('required')">Không được bỏ trống!</ng-container>
        <ng-container *ngIf="control.hasError('pattern')">
          Ít nhất 8 ký tự.<br />
          Ít nhất một ký tự chữ thường.<br />
          Ít nhất một ký tự chữ in hoa.<br />
          Ít nhất một chữ số.<br />
          Ít nhất một ký tự đặc biệt từ danh sách [#?!&#64;$%^&*-].<br />
        </ng-container>
      </ng-template>
      <ng-template #pricePrefix>
        <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="onViewPass()"></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item nz-row class="register-area">
    <nz-form-control [nzSpan]="14" [nzOffset]="6" [nzXs]="{ span: 14, offset: 0 }">
      <button nz-button [nzSize]="'small'" nzType="primary" class="m-r-10" [disabled]="!formInput.valid"
        (click)="onSave()">
        Lưu
      </button>
      <button *ngIf="!isExtension" [nzSize]="'small'" nz-button (click)="onThoat()">Thoát</button>
    </nz-form-control>
  </nz-form-item>
</form>

<nz-divider *ngIf="fromChatPage == false && isExtension == false " nzOrientation="left" nzDashed
  [nzText]="'Google Authenticator'"></nz-divider>
<div *ngIf="fromChatPage == false && isExtension == false " class="box-google-authen">
  <div>
    <button *ngIf="isUseGoogleAuthen == false" nz-button [nzSize]="'small'" nzSuccess nzType="primary" class="m-r-10"
      (click)="onRegisGoogleAuthenticator(false)">
      Đăng ký
    </button>
    <button *ngIf="isUseGoogleAuthen == true" nz-button [nzSize]="'small'" nzDanger nzType="primary" class="m-r-10"
      (click)="onUnRegisGoogleAuthenticator()">
      Hủy
    </button>
    <button *ngIf="isUseGoogleAuthen == true" nz-button [nzSize]="'small'" nzType="primary" class="m-r-10"
      (click)="onRegisGoogleAuthenticator(true)">
      Đăng ký mã khác
    </button>
  </div>
  <div *ngIf="keyGoogleAuthen" class="box-qrcode">
    <div class="qr-wrapper" (click)="toggleShowInputPassword()">
      <qr-code [value]="keyGoogleAuthen" size="200" errorCorrectionLevel="M" [margin]="1"></qr-code>
      <div class="overlay" *ngIf="isFaded">
        <div class="box-hidden">
          <div *ngIf="isShowInputPassword == false" class="text-hidden">
            <span>
              Click để hiển thị
            </span>
          </div>
          <div (click)="$event.stopPropagation()" *ngIf="isShowInputPassword == true" class="input-password">
            <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
              <input #myInput [nzSize]="'small'" nz-input [(ngModel)]="password"
                [type]="passwordQRCodeVisible ? 'text' : 'password'" (keyup.enter)="onCheckPassword()" />
            </nz-input-group>
            <ng-template #pricePrefix>
              <span nz-icon [nzType]="passwordQRCodeVisible ? 'eye-invisible' : 'eye'"
                (click)="onViewPassQRCode()"></span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div>
      Dùng ứng dụng 'Google Authenticator' để quét mã QRCode
    </div>
  </div>
</div>