<li *ngIf="isManagement" nz-menu-item (click)="onClickShowFiles()">
    <a class="p-v-5 d-flex align-items-center justify-content-between">
        <div>
            <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
            <span class="m-l-10">Thư viện của tôi</span>
        </div>
        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
    </a>
</li>

<div *ngIf="!isManagement" class="clearfix btn-handler">
    <div>
        <button (click)="onClickShowFiles()">Click me</button>
    </div>
</div>

<ng-template #templateListFolders>
    <div class="file-manager row">
        <div class="sidebar col-3">
            <nz-tree nzBlockNode [nzData]="folderTree" (nzClick)="activeNode($event)" (nzDblClick)="openFolder($event)"
                [nzTreeTemplate]="nzTreeTemplate" (contextmenu)="contextMenuDoNothing($event)">
            </nz-tree>
            <ng-template #nzTreeTemplate let-node let-origin="origin">
                <div class="w-100" (contextmenu)="contextMenu($event, menu, node)">
                    <span>
                        <i nz-icon [nzType]="node.isExpanded ? 'folder-open' : 'folder'" nzTheme="outline"
                            [ngStyle]="{'color': '#ffa940' }"></i>
                        {{ node.origin.ten }}
                    </span>
                </div>
            </ng-template>
        </div>

        <div class="content col-9">
            <div class="breadcrumb-container m-b-5">
                <div class="breadcrumb-content">
                    <ng-container *ngFor="let path of fullPath; let last = last">
                        <span class="breadcrumb-item" (click)="onPathClick(path)" [class.active]="last">
                            {{ path.ten }}
                        </span>
                        <i *ngIf="!last" nz-icon nzType="right" class="breadcrumb-separator"></i>
                    </ng-container>
                </div>
                <div class="breadcrumb-action" *ngIf="!isFolderTrash">
                    <button nz-button nzType="primary" nzSize="small" class="m-r-5"
                        (click)="onClickNewFolder($event)">Thêm thư mục</button>
                    <button nz-button nzType="primary" nzSize="small" (click)="onClickNewFile($event)">Thêm
                        file</button>
                </div>
            </div>
            <div class="list-file">
                <!-- Thư mục thường -->
                <nz-list *ngIf="!isFolderTrash" class="h-95" [nzBordered]="true" [nzDataSource]="lstInFolder"
                    [nzRenderItem]="item" (contextmenu)="contextMenuAction($event, menuAction)">
                    <ng-template #item let-file>
                        <div *ngIf="file.type === 'FOLDER'" class="file-item" style="cursor: pointer;"
                            (dblclick)="onClickChooseFolder(file)"
                            (contextmenu)="contextMenuFolder($event, menuFolder, file)">
                            <div class="file-info">
                                <i nz-icon [nzType]="'folder'" nzTheme="outline" [ngStyle]="{'color': '#ffa940'}">
                                </i>
                                <span class="file-name">{{ file.ten }}</span>
                            </div>
                            <div class="file-date">
                                {{ file.ngayTao | date :"HH:mm dd/MM/yyyy" }}
                            </div>
                        </div>
                        <div *ngIf="file.type === 'FILE'" class="file-item" [class.selected]="file.isSelected"
                            style="cursor: pointer;" (click)="onClickActiveFile(file)"
                            (dblclick)="onClickChooseFile(file)"
                            (contextmenu)="contextMenuFile($event, menuFile, file)">
                            <div class="file-info">
                                <i nz-icon [nzType]="'file'" nzTheme="outline" [ngStyle]="{'color': '#1890ff'}">
                                </i>
                                <span class="file-name">{{ file.ten }}</span>
                            </div>
                            <div class="file-date">
                                {{ file.ngayTao | date :"HH:mm dd/MM/yyyy" }}
                            </div>
                        </div>
                    </ng-template>
                </nz-list>

                <!-- Thư mục thùng rác  -->
                <nz-list *ngIf="isFolderTrash" class="h-95" [nzBordered]="true" [nzDataSource]="lstInFolder"
                    [nzRenderItem]="item" (contextmenu)="contextMenuDoNothing($event)">
                    <ng-template #item let-file>
                        <div *ngIf="file.type === 'FOLDER'" class="file-item" style="cursor: pointer;"
                            (contextmenu)="contextMenuFolderTrash($event, menuFolderTrash, file)">
                            <div class="file-info">
                                <i nz-icon [nzType]="'folder'" nzTheme="outline" [ngStyle]="{'color': '#ffa940'}">
                                </i>
                                <span class="file-name">{{ file.ten }}</span>
                            </div>
                            <div class="file-date">
                                {{ file.ngayTao | date :"HH:mm dd/MM/yyyy" }}
                            </div>
                        </div>
                        <div *ngIf="file.type === 'FILE'" class="file-item" style="cursor: pointer;"
                            (contextmenu)="contextMenuFileTrash($event, menuFileTrash, file)">
                            <div class="file-info">
                                <i nz-icon [nzType]="'file'" nzTheme="outline" [ngStyle]="{'color': '#1890ff'}">
                                </i>
                                <span class="file-name">{{ file.ten }}</span>
                            </div>
                            <div class="file-date">
                                {{ file.ngayTao | date :"HH:mm dd/MM/yyyy" }}
                            </div>
                        </div>
                    </ng-template>
                </nz-list>
            </div>
        </div>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickNewFolderInFolder($event)">Thêm thư mục</li>
            <li *ngIf="!activateFile.isSystem" nz-menu-item (click)="onClickRemoveFolder($event)">Xóa thư mục</li>
        </ul>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuAction="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickNewFile($event)">Thêm file</li>
            <li nz-menu-item (click)="onClickNewFolder($event)">Thêm thư mục</li>
        </ul>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuFolder="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickNewFileInFolder($event)">Thêm file</li>
            <li *ngIf="userLogin.isAdmin || userLogin.id == activateFile.idUser" nz-menu-item
                (click)="onClickNewFolderInFolder($event)">Thêm thư mục</li>
            <ng-container *ngIf="!activateFile.isSystem">
                <li *ngIf="userLogin.isAdmin || userLogin.id == activateFile.idUser" nz-menu-item
                    (click)="onClickUpdateFolderInFolder($event)">Cập nhật thư mục</li>
                <li nz-menu-item (click)="onClickRemoveFolder($event)">Xóa thư mục</li>
            </ng-container>
        </ul>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuFile="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickOpenFile($event)">Xem trước</li>
            <li *ngIf="this.userLogin.isAdmin || activateFile.idUser == this.userLogin.id" nz-menu-item
                (click)="onClickRemoveFile($event)">Xóa file</li>
        </ul>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuFolderTrash="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickRecoverFolder($event)">Khôi phục thư mục</li>
            <li nz-menu-item (click)="onClickRemoveFolderInTrash($event)">Xóa thư mục</li>
        </ul>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuFileTrash="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onClickRecoverFile($event)">Khôi phục file</li>
            <li nz-menu-item (click)="onClickRemoveFileInTrash($event)">Xóa file</li>
        </ul>
    </nz-dropdown-menu>

    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" [attr.accept]="extention"
        [attr.multiple]="''">

    <input type="file" #fileInputFolder style="display: none;" (change)="onFileSelectedInFolder($event)"
        [attr.accept]="extention" [attr.multiple]="''">

    <div *ngIf="!isManagement" class="custom-footer m-t-5">
        <button nz-button nzSize="small" class="m-r-5" nzType="primary" (click)="onClickOk()">Chọn</button>
        <button nz-button nzSize="small" (click)="onClickCancel()">Thoát</button>
    </div>

</ng-template>