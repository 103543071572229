import { Component, Renderer2 } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { MenuService } from 'src/app/services/core/menu.service';
import { UserService } from 'src/app/services/core/user.service';
import { UserLogin } from 'src/app/models/userlogin';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuConstantService } from '../../services/menu-constant.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent {

  public menuItems: any[]
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  userLogin: UserLogin;

  urlActive: string;
  constructor(private renderer: Renderer2,
    private themeService: ThemeConstantService,
    private pageService: MenuService,
    private router: Router,
    private _userService: UserService,
    private menuService: MenuConstantService,
  
    ) {

    }

  ngOnInit(): void {
    this.urlActive = this.router.url;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.urlActive = event.url;
      }
    });

    this.loadMenu();
    // this.menuItems = ROUTES.filter(menuItem => menuItem);

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);

    // console.log(this.menuItems)
    this._userService.getCurrentUser().subscribe(res =>{
      if(res){
          this.userLogin = res;
      }
    })
  }
  onViewHome(){
      if(this.userLogin && this.userLogin.homePage){
        this.router.navigate([this.userLogin.homePage]);
      }else{
        this.router.navigate(['/']);
      }
  }
  onHover(event, path, id) {
    const location = event.target.getBoundingClientRect();
    const idtext = 'lv3' + path + id
    const lv3 = document.getElementById(idtext)
    const lv3Rect = lv3.getBoundingClientRect();


    var check = (window.innerHeight - location.bottom) > lv3Rect.height
    // this.renderer.setStyle(lv3, 'left', (location.right) + 'px')
    // this.renderer.setStyle(lv3, 'top', location.top + 'px')
    if (check) {
      this.renderer.setStyle(lv3, 'left', location.right + 'px')
      this.renderer.setStyle(lv3, 'top', location.top + 'px')
      this.renderer.setStyle(lv3, 'bottom', 'inherit')
    } else {
      this.renderer.setStyle(lv3, 'left', location.right + 'px')
      this.renderer.setStyle(lv3, 'top', 'inherit')
      this.renderer.setStyle(lv3, 'bottom', window.innerHeight - location.bottom  + 'px')
    }
    // const element = document.querySelector('.ps-content')
    // this.renderer.setStyle(element, 'overflow', 'unset')
  }
  outHover(event, path, id) {
    // const element = document.querySelector('.ps-content')
    // this.renderer.setStyle(element, 'overflow', 'scroll')
  }

  loadMenu() {
    this.pageService.layTatcaMenu().then(rs => {
      if (rs.success) {
        this.menuItems = rs.data;
        this.menuService.changeMenuLoad(this.menuItems);
      }
    })
  }

  closeMobileMenu(): void {
    //debugger
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
  onMenuActive(menuItem,e){
    // debugger
    const location = e.target.getBoundingClientRect();
    const lv3 = e.target.parentElement.querySelectorAll('.sb-menu-lv2')[0];
    if(lv3){
      let locationLv3 = lv3.getBoundingClientRect();
      if (location.bottom * 2 < window.innerHeight) {
        this.renderer.setStyle(lv3, 'top', location.top + 'px')
        this.renderer.setStyle(lv3, 'bottom',  'auto')
      } else {
        if(location.bottom < locationLv3.height){
          this.renderer.setStyle(lv3, 'top',  '0px')
          this.renderer.setStyle(lv3, 'bottom',  'auto')
        }else{
          this.renderer.setStyle(lv3, 'bottom', window.innerHeight - location.bottom + 'px')
          this.renderer.setStyle(lv3, 'top',  'auto')
        }
        
      }
    }
    
    // Kiểm tra nếu phần tử tồn tại
    // if (elements) {
    //     elements.forEach((element) => {
    //       if (menuItem.path != "/quantri") {
    //         (element as HTMLElement).style.top = location.top+'px';
    //       }else{
    //         (element as HTMLElement).style.top = 'revert-layer';
    //       }
    //   });
    // } else {
    //     console.log('Không tìm thấy phần tử cần thay đổi CSS');
    // }

    this.menuItems.forEach(element => {
      element.active = false;
    });
    menuItem.active = !menuItem.active;
  }
  onMenuPanelClose($event, itemMenu){
    this.menuItems.forEach(element => {
      element.active = false;
    });
    $event.stopPropagation();
    // if(itemMenu){
    //   console.log(itemMenu)
    //   if(itemMenu.tabmenu && itemMenu.tabmenu.length > 0){
    //       let d1 = itemMenu.tabmenu.find(b => b.isMenuTabMacDinh);
    //       if(d1){
    //         this.router.navigate([d1.path]);
    //         return;
    //       }
    //       this.router.navigate([itemMenu.tabmenu[0].path]);
    //       return;

    //   }
    //   this.router.navigate([itemMenu.path]);
    //   return;
    // }
  }

  getClassMenu1(itemMenu){

    if(itemMenu && (itemMenu.path ==  this.urlActive ||  itemMenu.tabmenu.findIndex(b => b.path ==  this.urlActive)) >-1){
      if(itemMenu.submenu.length > 0){
        return 'ant-menu-submenu ant-menu-submenu-inline ant-menu-item-panel ant-menu-submenu-open';
      }else{
        return 'ant-menu-submenu ant-menu-submenu-inline ant-menu-item-panel ant-menu-item-selected';
      }

    }
    return 'ant-menu-submenu ant-menu-submenu-inline ant-menu-item-panel';

}

  getClassMenu(itemMenu){

    if(itemMenu && (itemMenu.path ==  this.urlActive ||  itemMenu.tabmenu.findIndex(b => b.path ==  this.urlActive)) >-1){
      if(itemMenu.submenu.length > 0){
        return 'ant-menu-item ant-menu-submenu ant-menu-submenu-inline ant-menu-cap2 ant-menu-submenu-open';
      }else{
        return 'ant-menu-item ant-menu-submenu ant-menu-submenu-inline ant-menu-cap2 ant-menu-item-selected';
      }
    }
      if(itemMenu && itemMenu.submenu.length > 0){
          if(itemMenu.submenu.length > 0){
              return 'ant-menu-submenu ant-menu-submenu-inline ant-menu-cap2';
          }else{

            return 'ant-menu-item';
          }
      }
      return 'ant-menu-item';

  }

}
