import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment } from '../../../environments/environment';
import { ResponseData } from '../../models/response';

@Injectable({
  providedIn: 'root',
})
export class NhanVienCustomService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/User`);
  }

  GetUserById(id) {
    let url = `${environment.CORE_API}api/User/GetUserById/${id}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  GetAllNhomNhanVien() {
    let url = `${environment.CORE_API}api/NhomNhanVien/LayTatCa`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  ThemMoiNhanVien(body) {
    const url = `${this.table}/ThemMoiNhanVien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  GetNhomNhanVienTheoBangLuong(idBangLuong) {
    const url = `${this.table}/GetNhomNhanVienTheoBangLuong?idBangLuong=${idBangLuong}`;
    return this.get<ResponseData>(url).toPromise();
  }

  TimTheoDieuKienNhanVien(body) {
    const url = `${this.table}/TimTheoDieuKienNhanVien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  KhoaTaiKhoan(payload): Promise<ResponseData> {
    const url = `${this.table}/KhoaTaiKhoan`;
    return this.httpClient.post<ResponseData>(url, payload).toPromise();
  }

  GetListNhomByDonVi() {
    let url = `${environment.CORE_API}api/NhomNhanVien/GetListByDonVi`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }
}
