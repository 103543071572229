import { REGEX } from './../../../constants/config';

import { Component, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from 'src/app/models/response';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { UserService } from 'src/app/services/core/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { CryptHelperService } from 'src/app/services/cryptohelper.service';

@Component({
  selector: 'app-form-user-changepass',
  templateUrl: './form-user-changepass.component.html',
  styleUrls: ['./form-user-changepass.component.css'],
})
export class FormUserChangePassComponent implements OnInit {
  isUseGoogleAuthen: boolean = false;
  keyGoogleAuthen: string = null;
  isShowKeyGoogleAuthen: boolean = false;
  @Input() fromChatPage: boolean = false;
  isLoading = false;
  id: any;
  isExtension: boolean = false;
  formInput: FormGroup;

  passwordVisible = false;
  passwordQRCodeVisible = false;
  listOfRole = [];

  modelUser: any = {};
  modelDonVi: any = {};
  modelUserName: string;
  isShowInputPassword: boolean = false;
  isFaded = true;

  password: string = null;
  @ViewChild('myInput') myInput!: ElementRef;

  toggleShowInputPassword() {
    this.isShowInputPassword = !this.isShowInputPassword
    if (this.isShowInputPassword == false) {
      this.isFaded = true;
    }
    if (this.isShowInputPassword == true) {
      setTimeout(() => {
        this.myInput.nativeElement.focus();
      }, 10);
    }
  }

  toggleFade() {
    this.isFaded = false;
    setTimeout(() => {
      this.isFaded = true;
    }, 5000);
  }


  constructor(
    private fb: FormBuilder,
    @Optional() private modal: NzModalRef,
    private _userService: UserService,
    private _authenService: AuthService,
    private spinner: NgxSpinnerService,
    private _modalService: NzModalService,
    private _notification: NzNotificationService,
    private _cryptHelperService: CryptHelperService,
  ) { }

  ngOnInit() {
    this.onGetGoogleAuthen();
    this.formInput = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [
        null,
        [Validators.required, Validators.pattern(REGEX.PASSWORD)],
      ],
      confirmPassword: [
        null,
        [Validators.required, Validators.pattern(REGEX.PASSWORD)],
      ],
    });
    this.isExtension = ((localStorage.getItem("isExtension") as unknown) as boolean) || this.fromChatPage;
  }

  onViewPass() {
    this.passwordVisible = !this.passwordVisible;
  }

  onViewPassQRCode() {
    this.passwordQRCodeVisible = !this.passwordQRCodeVisible;
  }

  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  onSave() {
    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      return;
    }
    let model = this.formInput.value;
    this.spinner.show();
    this._userService.userDoiMatKhau(model).then((res: ResponseData) => {
      this.spinner.hide();
      if (res.success) {
        this._notification.success(MESSAGE.SUCCESS, 'Cập nhật thành công');
        this.modal?.close(true);
      } else {
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    });
  }

  onGetGoogleAuthen() {
    this.spinner.show();
    this._authenService.LayThongTinGoogleAuthenticator().then((res: ResponseData) => {
      this.spinner.hide();
      if (res && res.success && res.data) {
        this.keyGoogleAuthen = res.data.googleAuthenticatorSecretKey;
        this.isUseGoogleAuthen = res.data.isUseGoogleAuthenticator ?? false;
      } else {
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    });
  }


  onCheckPassword() {
    if (this.password == null || this.password == undefined || this.password == '') {
      this._notification.warning(MESSAGE.WARNING, "Vui lòng nhập mật khẩu");
      return;
    }
    let body = {
      password: this._cryptHelperService.Encrypt_DataUser(this.password)
    }
    this.spinner.show();
    this._userService.CheckPassword(body).then((res: ResponseData) => {
      this.spinner.hide();
      if (res && res.success && res.data) {
        this.toggleFade();
      } else {
        this._notification.error(MESSAGE.ERROR, res.message ?? "Mật khẩu không đúng");
      }
    });
  }

  onRegisGoogleAuthenticator(isReset = false) {
    let text = 'Bạn có chắc chắn muốn đăng ký đăng nhập cùng Google Authenticator?'
    if (isReset == true) {
      text = 'Bạn có chắc chắn muốn đổi mã?'
    }
    this._modalService.confirm({
      nzClosable: false,
      nzTitle: 'Xác nhận',
      nzContent: text,
      nzOkText: 'Đồng ý',
      nzCancelText: 'Không',
      nzOkDanger: true,
      nzWidth: 310,
      nzOnOk: () => {
        this.spinner.show();
        this._authenService.DangKySuDungGoogleAuthenticator().then((res: ResponseData) => {
          this.spinner.hide();
          if (res && res.success && res.data) {
            this.isUseGoogleAuthen = true;
            this.keyGoogleAuthen = res.data
          }
          else {
            this._notification.error(MESSAGE.ERROR, res.message ?? "Xảy ra lỗi trong khi đăng ký")
          }
        })
      }
    });
  }

  onUnRegisGoogleAuthenticator() {
    this._modalService.confirm({
      nzClosable: false,
      nzTitle: 'Xác nhận',
      nzContent: 'Bạn có chắc chắn muốn hủy đăng ký đăng nhập cùng Google Authenticator?',
      nzOkText: 'Đồng ý',
      nzCancelText: 'Không',
      nzOkDanger: true,
      nzWidth: 310,
      nzOnOk: () => {
        this.spinner.show();
        this._authenService.HuySuDungGoogleAuthenticator().then((res: ResponseData) => {
          this.spinner.hide();
          if (res && res.success && res.data) {
            this.isUseGoogleAuthen = false;
            this.keyGoogleAuthen = null
          }
          else {
            this._notification.error(MESSAGE.ERROR, res.message ?? "Xảy ra lỗi trong khi hủy đăng ký")
          }
        })
      }
    });
  }

  onThoat() {
    this.modal?.close(false);
  }
}
