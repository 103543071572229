import { Guid } from "guid-typescript";

export class PageInfo {
  page: number = 1
  pageSize: number = 10

  constructor(_page: number, _pageSize: number) {
    this.page = _page;
    this.pageSize = _pageSize;

  }
}
export class GridOption {
  pageInfo: PageInfo;
  sorts: Array<any>;

  /**
   *
   */
  constructor(page, pageSize, sorts = []) {
    this.pageInfo = new PageInfo(page, pageSize);
    this.sorts = sorts
  }
}

export class GridOptionSearchInput {
  pageInfo: PageInfo;
  sorts: Array<any>;
  keyword: string;
  constructor(keyword: string, pageSize: number, page: number, sorts: Array<any>) {
    this.pageInfo = new PageInfo(page, pageSize);
    this.sorts = sorts;
    this.keyword = keyword
  }
}

export class PayloadDropdownAutoComplete extends GridOptionSearchInput {
  listIdSelected?: Array<any> | null
  listIdExisted?: Array<any> | null
  listGet?: Array<any> | null
}
