<form nz-form [formGroup]="formInput">
    <nz-form-item>
        <nz-form-label [nzSpan]="6" nzFor="folderName">Tên thư mục</nz-form-label>
        <nz-form-control [nzSpan]="18" nzErrorTip="Tên thư mục là bắt buộc">
            <input nz-input formControlName="folderName" id="folderName" />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="6">Riêng tư</nz-form-label>
        <nz-form-control [nzSpan]="18">
            <label nz-checkbox formControlName="isPrivate">Chỉ mình tôi</label>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="6">Nhân viên</nz-form-label>
        <nz-form-control [nzSpan]="18">
            <nz-select formControlName="allowedUsers" nzMode="multiple" nzPlaceHolder="Chọn nhân viên">
                <nz-option *ngFor="let user of users" [nzValue]="user.id" [nzLabel]="user.userName"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="6">Vai trò</nz-form-label>
        <nz-form-control [nzSpan]="18">
            <nz-select formControlName="allowedRoles" nzMode="multiple" nzPlaceHolder="Chọn vai trò">
                <nz-option *ngFor="let role of roles" [nzValue]="role.id" [nzLabel]="role.ten"></nz-option>
            </nz-select>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-control [nzOffset]="6">
            <button nz-button nzSize="small" nzType="primary" (click)="submitForm()">Tạo thư mục</button>
        </nz-form-control>
    </nz-form-item>
</form>