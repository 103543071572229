import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-datlich-layout',
    templateUrl: './datlich-layout.component.html'
})

export class DatLichLayoutComponent implements OnInit  {
    constructor() { }

    ngOnInit() {
        const interval = setInterval(() => {
          const vnpCheck = document.getElementById('vnp_chat');
          const vnpGuide = document.getElementById('id-box-to-guide');
          const vnpBars = document.getElementById('id-box-icon-bar');
          if (vnpCheck && vnpGuide && vnpBars) {
            vnpCheck.style.display = 'none';
            vnpGuide.style.display = 'none';
            vnpBars.style.display = 'none';
            clearInterval(interval); // Ngừng kiểm tra sau khi đã ẩn thành công
          }
        }, 200); // Kiểm tra mỗi 500ms
      }
}