
import { AfterViewChecked, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router } from '@angular/router';
import { isArray } from 'highcharts';
import { concat, Observable, of, Subject, map, Subscription } from 'rxjs';
import { MenuConstantService } from '../../services/menu-constant.service';

export class MenuTabItem {
  id?: string;
  idParent?: string = "123123";
  ten: string;
  url: string;
  permision: string;
  icon: string;
  submenu: Array<any>
}


@Component({
  selector: 'sb-menu-tab',
  templateUrl: './sb-menu-tab.component.html',
  styleUrls: ['./sb-menu-tab.component.scss'],
})
export class SbMenuTabComponent implements OnInit, OnDestroy {

  MenuData: Array<MenuTabItem>;
  isLoading = false;
  MenuRouter: Array<any> = [];
  urlActive: string;
  levelTabMenu: number = 0;

  subMenuShow: Array<any> = [];

  private menuSub: Subscription;
  private routerSub: Subscription;

  titlePage: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: MenuConstantService,
    private titleService: Title,

  ) { }


  ngOnDestroy(): void {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }


  ngOnInit(): void {
    this.urlActive = this.router.url;
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.MenuData = [];
        this.urlActive = event.url;
        this.urlActive = this.xulyUrl(this.urlActive);

        console.log(this.urlActive);
        this.FindMenuTab(this.MenuRouter);
      }
    });

    this.activatedRoute.children.forEach(child => {
      child.params.subscribe(params => {
        if (params) {
          Object.keys(params).forEach(key => {
            this.urlActive = this.urlActive.replace("/" + params[key], '');
          });
          this.urlActive = this.xulyUrl(this.urlActive);
        }
      })
    });

    this.menuSub = this.themeService.isMenuChanges.subscribe(
      (mn) => {
        this.MenuData = [];
        this.MenuRouter = mn;
        this.FindMenuTab(this.MenuRouter);
      }
    );

  }

  xulyUrl(url: string): string {
    let thirdSlashIndex = this.findThirdSlashIndex(url);
    if (thirdSlashIndex !== -1) {
      // Cut the string from the third '/' character
      return url.substring(0, thirdSlashIndex);
    }
    return url;
  }

  findThirdSlashIndex(input: string): number {
    let count = 0;
    for (let i = 0; i < input.length; i++) {
      if (input[i] === '/') {
        count++;
        if (count === 3) {
          return i;
        }
      }
    }
    return -1; // Return -1 if less than three slashes are found
  }

  onChangeModel(event) {
    this.router.navigate([event]);
  }

  ActiveTab(tabItem) {
    if (this.urlActive) {
      let item = this.urlActive + '/';
      //Kiểm tra menu hiện tại và menu con có active không
      return (item.indexOf(tabItem.url + '/') > -1 || (tabItem.submenu && tabItem.submenu.filter(b => item == (b.url + '/')).length > 0)) ? 'active' : '';
    } else {
      return '';
    }

  }

  
  

  FindMenuTab(menu, isCheckParent = false) {
    if (menu && menu.length > 0) {
      let urlActive = this.urlActive;
      let tabLv3 = null;
      if (isCheckParent == true || this.themeService.getPageChoose()?.idParent != null) {
        menu.some(element => {
          tabLv3 = element?.tabmenu?.find(b => b.id == this.themeService.getPageChoose()?.idParent && b.isMenuTab == true);
          if (tabLv3 != null) {
            return true;
          }
        })

        if (tabLv3 != null && tabLv3 != undefined && tabLv3.path != null) {
          urlActive = tabLv3.path;
        }
      }
      menu.forEach(element => {
        //thiết lập title page
        if(element.path == this.urlActive){
          this.titleService.setTitle(element.title);
        }else if(element.tabmenu){
            let r = element.tabmenu.find(b => this.urlActive == b.path);
            if(r){
              this.titleService.setTitle(r.title);
            }
        }
        if (element.tabmenu && element.tabmenu.findIndex(b => urlActive == b.path && b.isMenuTab == true) > -1) {
          if (this.levelTabMenu == 2) {
            let tabLv3 = element.tabmenu.find(b => urlActive == b.path && b.isMenuTab == true);
            this.themeService.setPageChoose(tabLv3.id, tabLv3.idParent);
            this.FindMenuTab(this.MenuRouter, true);
            return;
          }
          this.MenuData = element.tabmenu.map(b => {
            let subtab = [];
            if (b.submenu && b.submenu.length > 0) {
              subtab = b.submenu.map(c => {
                return {
                  id: c.id,
                  idParent: c.idParent,
                  ten: c.title,
                  url: c.path,
                  permision: null,
                  icon: c.icon,
                  submenu: []
                }
              });
            }
            return {
              id: b.id,
              idParent: b.idParent,
              ten: b.title,
              url: b.path,
              permision: null,
              icon: b.icon,
              submenu: subtab
            }
          });
          return;
        } else if (element.tabmenu && element.tabmenu.length > 0 && element.tabmenu.findIndex(b => b.submenu && b.submenu.length > 0) > -1) {
          element.tabmenu.forEach(b => {
            if (b.submenu && b.submenu.length > 0) {
              this.levelTabMenu = 2;
              let a = { ...b };
              a.tabmenu = a.submenu;
              this.FindMenuTab([a]);
            }
          });
        }
        if (element.submenu && element.submenu.length > 0) {
          this.levelTabMenu = 0;
          this.FindMenuTab(element.submenu);
        }
      });
    }
  }
  onClickParent(menu) {
    this.subMenuShow = menu;
  }

  

  onClick(item) {
    if (item.url == '#') {

    } else {
      if (item.url) {
        this.themeService.setPageChoose(item.id, item.idParent);
        this.router.navigate([item.url]);
      }
    }
  }

}
