<div *ngIf="!isLoading">
  <nz-skeleton></nz-skeleton>
</div>

<form nz-form [formGroup]="formInput" *ngIf="isLoading" class="responsive-padding">

  <div nz-row class="form-nhanvien">

    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="ma">Ảnh đại diện</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <app-file-upload formControlName="avatarUrl" [onlyImage]="true"></app-file-upload>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="userName">User Name</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <input [nzSize]="'small'" nz-input formControlName="userName" [maxlength]="50" type="text" [readOnly]="true">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="ma">Tên đơn vị</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <input [nzSize]="'small'" nz-input formControlName="tenDonVi" [maxlength]="200" type="text" [readOnly]="true">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6"
          [nzXs]="24" nzFor="ten">Họ và tên</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24"
          nzErrorTip="Họ và tên không được bỏ trống!">
          <input [nzSize]="'small'" nz-input formControlName="ten" type="text" [maxlength]="100">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="ten">Tên chi nhánh</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <input [nzSize]="'small'" nz-input formControlName="tenChiNhanh" type="text" [maxlength]="100"
            [readOnly]="true">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="ten">Ngày sinh</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24"
          nzErrorTip="Ngày sinh không được bỏ trống!">
          <app-date-picker formControlName="ngaySinh"></app-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="ten">Giới tính</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <nz-select [nzSize]="'small'" nzPlaceHolder="" formControlName="gioiTinh">
            <nz-option *ngFor="let option of listGioiTinh" [nzLabel]="option.ten" [nzValue]="option.id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>


    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="soDienThoai">Số điện thoại</nz-form-label>
        <!-- 20241115: Component Phone Regex -->
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24"
          nzErrorTip="Giá trị được nhập phải là số điện thoại!">
          <!-- <input [nzSize]="'small'" nz-input formControlName="soDienThoai" [maxlength]="12" type="text"> -->
          <app-input-phone-number-regex formControlName="soDienThoai" [size]="'small'" [placeholder]="'Số điện thoại'">
          </app-input-phone-number-regex>
          <!-- <ng-template #phoneErrorTpl let-control>
            <ng-container *ngIf="control.hasError('pattern')">Không đúng định dạng số điện thoại!</ng-container>
            <ng-container *ngIf="control.hasError('required')">Số điện thoại không được bỏ trống!</ng-container>
          </ng-template> -->
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="url">Quyền</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <nz-tag [nzColor]="'#108ee9'" *ngFor="let e of listOfRole">{{e}}</nz-tag>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="email">Email</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24" [nzErrorTip]="emailErrorTpl">
          <input [nzSize]="'small'" nz-input formControlName="email" [maxlength]="100" type="text">
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('email')">Nhập không đúng định dạng email!</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12" [nzXs]="24">
      <nz-form-item>
        <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
          nzFor="diaChi">Địa chỉ</nz-form-label>
        <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
          <input [nzSize]="'small'" nz-input formControlName="diaChi" [maxlength]="500" type="text">
        </nz-form-control>
      </nz-form-item>
    </div>


    <ng-container *ngIf="IsCoQuyen()">
      <nz-divider></nz-divider>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24">Tài
            khoản</nz-form-label>
          <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">

            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
              <input type="text" nz-input [ngModelOptions]="{standalone: true}" [(ngModel)]="modelUserName"
                placeholder="Tên đăng nhập" />
            </nz-input-group>
            <ng-template #suffixIconButton>
              <button [nzSize]="'small'" nz-button nzType="primary" nzSearch (click)="searchUser($event)"><span nz-icon
                  nzType="search"></span></button>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12"></div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" [nzSm]="6" [nzXs]="24"
            nzFor="diaChi">Đơn vị</nz-form-label>
          <nz-form-control [nzLg]="fromChatPage ? 24 : 16" [nzSm]="14" [nzXs]="24">
            <app-dropdown-donvi-one [ngModelOptions]="{standalone: true}"
              [(ngModel)]="modelDonVi"></app-dropdown-donvi-one>
          </nz-form-control>
        </nz-form-item>
      </div>

    </ng-container>
  </div>
  <nz-form-item nz-row class="register-area">
    <nz-form-control [nzSpan]="14" [nzOffset]="6" [nzXs]="{ span: 14, offset: 0 }">
      <button [nzSize]="'small'" nz-button nzType="primary" class="m-r-10" (click)="onSave()">Lưu</button>
      <button *ngIf="IsCoQuyen()" [nzSize]="'small'" nz-button nzType="primary" class="m-r-10"
        (click)="onResetToDefault()">Cài về mặc định</button>
      <button *ngIf="!isExtension" [nzSize]="'small'" nz-button (click)="onThoat()">Thoát</button>
    </nz-form-control>
  </nz-form-item>
</form>