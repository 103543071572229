import { FormGroup } from '@angular/forms';
import { PAGE_SIZE_DEFAULT, SORT_DIRECTION } from './config';
import { GridOptionSearchInput } from './gridOption';
import { GridColumn } from './form-schema';
import { NzOptionComponent } from 'ng-zorro-antd/select';

export class BaseEditComponent {
  saveLoading = false;
  formInput: FormGroup;
  id: any;
  timerId: any;
  indexTab: number = 0;
  listField: any[] = [];
  isCollapse = false;
  flagTimer: any;
  initSuccess: boolean = false;

  protected gridData: Array<any> = [];
  protected gridColumns: Array<GridColumn> = [];
  protected gridColumnsExportExcel: Array<GridColumn> = [];

  protected page: number = 1;
  protected pageSize: number = PAGE_SIZE_DEFAULT;
  protected totalRecord: number = 0;
  protected sorts: Array<{ key: string; value: 'ascend' | 'descend' | null }> =
    [];
  protected getGridOptionSearchInput(
    keyword: string | null = '',
    pageSize: number | null = 20,
    page: number | null = 1,
    sorts: Array<any> | null = []
  ) {
    return new GridOptionSearchInput(keyword, pageSize, page, sorts);
  }
  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  checkFieldValid() {
    let indexTabError = -1;
    if (this.formInput.invalid) {
      for (const i in this.formInput.controls) {
        if (this.formInput.controls[i].status == 'INVALID') {
          indexTabError = this.listField.find(
            (x) => x.nameField == i
          )?.indexTab;
          if (indexTabError == this.indexTab) {
            return false;
          }
        }
      }
      if (indexTabError != -1) {
        this.indexTab = indexTabError;
      }
      return false;
    }
    return true;
  }

  onQueryParamsChange(event) {
    if (this.initSuccess == true) {
      if (event.sort) {
        this.sorts = event.sort
          .filter((e) => {
            return e.value;
          })
          .map((e) => {
            return {
              field: e.key,
              dir:
                e.value === 'ascend'
                  ? SORT_DIRECTION.ASCE
                  : SORT_DIRECTION.DESC,
            };
          });
        this.gridLoadData();
      }
    }
    this.initSuccess = true;
  }

  sbFilterDropdown(input?: string, option?: NzOptionComponent) {
    function removeVietnameseTones(str: string): string {
      return str
        .normalize('NFD') // Tách các dấu và chữ cái
        .replace(/[\u0300-\u036f]/g, '') // Loại bỏ các dấu
        .replace(/đ/g, 'd') // Thay thế ký tự 'đ' thường
        .replace(/Đ/g, 'D'); // Thay thế ký tự 'Đ' hoa
    }
    if (input && option?.nzLabel && !option?.nzDisabled) {
      const normalizedSearchText = removeVietnameseTones(input.toLowerCase());
      const normalizedOptionLabel = removeVietnameseTones(
        option?.nzLabel?.toString().toLowerCase()
      );
      return normalizedOptionLabel.includes(normalizedSearchText);
    }
    return false;
  }

  gridLoadData() { }

  onDebounce(
    callback: (filter: GridOptionSearchInput) => any,
    keyword: string | '',
    delay: number | null = 300,
    pageSize: number | null = 20
  ) {
    if (this.flagTimer) {
      clearTimeout(this.flagTimer);
    }
    let filter = {
      pageInfo: { page: 1, pageSize: 20 },
      sorts: [],
      keyword: keyword,
    };
    this.flagTimer = setTimeout(() => {
      const func = callback.bind(this);
      func(filter);
    }, delay);
  }


  onDebounceScrollLoad(callback: (filter: GridOptionSearchInput) => any, keyword: string | '', delay: number | null = 300, pageSize: number | null = 20, page: number | null = 1) {
    if (this.flagTimer) {
      clearTimeout(this.flagTimer);
    }
    if (page == null || page == undefined) {
      this.page = this.page != null && this.page != undefined ? this.page + 1 : 1;
      page = this.page;
    }
    let filter = { pageInfo: { page: page, pageSize: 20 }, sorts: [], keyword: keyword, keyWord: keyword }
    this.flagTimer = setTimeout(() => {
      const func = callback.bind(this);
      func(filter);
    }, delay);
  }

  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  getRowIndex(i) {
    return (this.page - 1) * this.pageSize + i + 1;
  }

  getRowsSelect() {
    return this.gridData.filter((b) => b.isRowCheck);
  }

  onExit() { }
}
export interface IListField {
  nameField: string;
  indexTab: number;
}
