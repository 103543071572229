import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseEditComponent } from 'src/app/constants/base-edit.component';
import { GridOption, PageInfo } from 'src/app/constants/gridOption';
import { MESSAGE } from 'src/app/constants/message';
import { UserLogin } from 'src/app/models/userlogin';
import { NhanVienCustomService } from 'src/app/services/caidat-hethong/nhanvien-custom.service';
import { RoleService } from 'src/app/services/core/role.service';
import { UserService } from 'src/app/services/core/user.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-file-add-folder',
  templateUrl: './file-add-folder.component.html',
  styleUrls: ['./file-add-folder.component.scss']
})
export class FileAddFolderComponent extends BaseEditComponent implements OnInit {
  @Input() idParent;
  @Input() isUpdate;

  users = [];
  roles = [];
  userLogin: UserLogin;

  constructor(
    private modal: NzModalRef,
    private _notification: NzNotificationService,
    private fb: FormBuilder,
    private _fileService: FileService,
    private _roleDvService: RoleService,
    private nhanVienService: NhanVienCustomService,
    private _userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    if (this.isUpdate) {
      this.layThongTinThuMuc();
    }
    this.layQuyenDonVi();
    this.layNhanVienDonVi();
  }

  initForm() {
    this.formInput = this.fb.group({
      idParent: this.idParent,
      folderName: ['', [Validators.required]],
      isPrivate: [false],
      allowedUsers: [[]],
      allowedRoles: [[]]
    });
  }

  layQuyenDonVi(): void {
    this._roleDvService.layTatca().then((rs) => {
      this.roles = rs.data;
    });
  }

  layThongTinThuMuc(): void {
    const body = {
      id: this.idParent
    };
    this.spinner.show();
    this._fileService.GetFolderDetailById(body).then((res) => {
      if (res.success) {
        this.formInput = this.fb.group({
          id: [res.data?.id || ''],
          folderName: [res.data?.ten || '', [Validators.required]],
          isPrivate: [res.data?.isPrivate || false],
          allowedUsers: [res.data?.allowedUsers || []],
          allowedRoles: [res.data?.allowedRoles || []]
        });
      } else {
        this._notification.error('Thông báo', res.message);
        this.modal.close();
      }
      this.spinner.hide();
    }).catch((err) => {
      this._notification.error('Thông báo', 'Lấy thông tin thư mục thất bại');
      this.spinner.hide();
    });
  }

  getCurrentUser() {
    this._userService.getCurrentUser().subscribe((res) => {
      if (res) {
        this.userLogin = res;
      }
    });
  }

  layNhanVienDonVi() {
    let filter = {
      ...this.getGridOption(),
      idDonVi: this.userLogin?.donViId
    };
    this.nhanVienService.TimTheoDieuKienNhanVien(filter).then((res) => {
      if (res.success) {
        this.users = res.data;
      } else {
        this._notification.error(MESSAGE.ERROR, 'Lấy dữ liệu thất bại');
      }
    });
  }

  getGridOption() {
    return new GridOption(1, 999, [])
  }

  submitForm() {
    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      this._notification.error('Thông báo', 'Hãy nhập đầy đủ thông tin thư mục');
      return;
    }

    if (this.isUpdate) {
      const body = {
        ...this.formInput.value
      };
      this.spinner.show();
      this._fileService.UpdateFolderById(body).then((res) => {
        if (res.success) {
          this._notification.success('Thông báo', 'Thêm thư mục thành công');
        } else {
          this._notification.error('Thông báo', res.message);
        }
        this.spinner.hide();
        this.modal.close();
      }).catch((err) => {
        this._notification.error('Thông báo', 'Thêm thư mục thất bại');
        this.spinner.hide();
      });
    } else {
      const body = {
        ...this.formInput.value,
        idParent: this.idParent
      };
      this.spinner.show();
      this._fileService.CreateFolderByUnitId(body).then((res) => {
        if (res.success) {
          this._notification.success('Thông báo', 'Thêm thư mục thành công');
        } else {
          this._notification.error('Thông báo', res.message);
        }
        this.spinner.hide();
        this.modal.close();
      }).catch((err) => {
        this._notification.error('Thông báo', 'Thêm thư mục thất bại');
        this.spinner.hide();
      });
    }
  }
}
