import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { ResponseData } from 'src/app/models/response';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/Role`);
  }

  LayTatCa(): Promise<ResponseData> {
    const url = `${this.table}/LayTatCa`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
  GetAllRolePhongBanNhom(): Promise<ResponseData> {
    const url = `${this.table}/GetAllRolePhongBanNhom`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
}
